import React from 'react';

import { FormikProps } from 'formik';
// eslint-disable-next-line import/no-named-as-default
import FormControl from '@nib-components/form-control';
import Textarea from '@nib-components/textarea';

interface TextAreaFormControlProps extends Omit<FormikProps<any>, 'values' | 'touched' | 'errors'> {
  value: string;
  isTouched?: boolean;
  fieldName: string;
  label: string;
  id: string;
  valid?: boolean;
  error?: string;
}

const TextAreaFormControl = (props: TextAreaFormControlProps): JSX.Element => (
  <FormControl
    onFocus={() => props.setFieldTouched(props.fieldName, false)}
    onBlur={() => props.setFieldTouched(props.fieldName, true)}
    onChange={props.handleChange}
    validated={props.isTouched}
    valid={props.valid}
    value={props.value}
    id={props.id}
    name={props.fieldName}
    label={props.label}
    error={props.error}
    disabled={props.isSubmitting}
    width="100%"
  >
    <Textarea />
  </FormControl>
);

export default TextAreaFormControl;
