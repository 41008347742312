import axios from 'axios';
import config from '../../utils/env';
import { ComplaintModel } from '../../constructs/models';
import { v4 as uuidv4 } from 'uuid';

const submitComplaint = (data: ComplaintModel) => {
  data.complaintsReferenceId = uuidv4();
  return axios.post(config.complaintApiUrl, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export default submitComplaint;
