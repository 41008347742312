import React from 'react';
import Helmet from 'react-helmet';
import { Formik, FormikProps } from 'formik';
import styled from 'styled-components';

import Layout from '../components/Layout';
import TextboxFormControl from '../components/FormControl/TextboxFormControl';
import TextAreaFormControl from '../components/FormControl/TextAreaFormControl';

import Section from '@nib-components/section';
import Copy, { Bold } from '@nib-components/copy';
import Heading from '@nib-components/heading';
import Link from '@nib-components/link';
import Form from '@nib-components/form';
import { Stack, Columns, Column, Box } from '@nib/layout';
import { PrimaryButton } from '@nib-components/button';
import Alert from '@nib-components/alert';
import { copyFontSizeSmall } from '@nib-components/theme';

import { CommErrorMessage } from '../components/shared/ContactUsApiCommon';
import {
  validateNames,
  validatePhoneNumbers,
  validateEmail,
  isEmpty,
} from '../utils/form-validation';
import { ComplaintModel } from '../constructs/models';
import submitComplaint from '../components/shared/ComplaintApiService';

import metrics from '../metrics';
import Card from '@nib/card';

const metaDescription =
  'Complaints for health insurance. Our award-winning team are available Mon - Fri  8am to 6pm NZST. Just call 0800 123 642.';

const Footnote = styled.div`
  font-style: italic;
  font-size: ${copyFontSizeSmall};
  > ul {
    margin-top: 0;
  }
`;

const ComplaintSection = (): JSX.Element => (
  <Section>
    <Stack space={{ xs: 2, md: 4 }}>
      <Heading size={1}>Complaints</Heading>
      <Copy measure={false}>
        It is important to us that you are happy with your cover and our service. If there’s a
        problem, let us know so we can try and resolve the issue quickly and fairly.
      </Copy>
      <Copy measure={false}>
        To make a complaint please complete <Link href="#complaintForm">this form</Link>.
      </Copy>
      <Copy>Or contact us directly using details below:</Copy>
      <Columns space={{ xs: 4, md: 6 }} collapseBelow="md">
        <Column>
          <Card>
            <Card.Content title="Health Insurance">
              <Copy>
                <Bold>Email:</Bold>{' '}
                <Link href="mailto:complaints@nib.co.nz">complaints@nib.co.nz</Link>
              </Copy>
              <Copy>
                <Bold>Phone</Bold> <Link href="tel:0800123642">0800 123 642</Link>
              </Copy>
            </Card.Content>
          </Card>
        </Column>
        <Column>
          <Card>
            <Card.Content title="Life & Living and certain other products*">
              <Copy>
                <Bold>Email:</Bold>{' '}
                <Link href="mailto:lifeservice@nib.co.nz">lifeservice@nib.co.nz</Link>
              </Copy>
              <Copy>
                <Bold>Phone</Bold> <Link href="tel:0800555642">0800 555 642</Link>
              </Copy>
            </Card.Content>
          </Card>
        </Column>
      </Columns>

      <Footnote measure={false}>
        *You can also use these details for complaints about certain other nib nz insurance products
        that are no longer on sale:
        <ul>
          <li>Home Loan Insurance,</li>
          <li>Credit Card Repayment Insurance issued after 10 April 2011, and</li>
          <li>Life & Living Insurance issued before 16 August 2016</li>
        </ul>
      </Footnote>
      <Copy measure={false}>
        An nib staff member will try to resolve your complaint in the first instance. If we can’t do
        that, the complaint will be escalated to a team leader or manager who will contact you
        within two business days.
      </Copy>
      <Copy measure={false}>
        Any complaints not resolved by the team leader or manager will be escalated to the nib
        complaints committee – a group of senior managers who meet to review and look to resolve
        escalated complaints.
      </Copy>
      <Copy measure={false}>
        We will try to resolve your complaint within 14 days. If that is not possible, we will keep
        you updated with our progress.
      </Copy>
      <Heading size={3}>Getting an external review of the decision</Heading>
      <Copy measure={false}>
        We'll make every possible effort to resolve your complaint quickly and fairly. If you are
        not satisfied with the outcome of your complaint, you have access to the Insurance &
        Financial Services Ombudsman (IFSO), a free, independent dispute resolution service.
      </Copy>
      <Copy measure={false}>
        To contact the IFSO please use their online{' '}
        <Link href="https://www.ifso.nz/make-a-complaint">complaint form</Link> or contact them:
      </Copy>
      <Stack space={1}>
        <Copy measure={false}>
          <Bold>Phone:</Bold> 0800 888 202
        </Copy>
        <Copy measure={false}>
          <Bold>Email:</Bold> info@ifso.nz
        </Copy>
        <Copy measure={false}>
          <Bold>Post:</Bold> IFSO Scheme PO Box 10-845 Wellington 6143 New Zealand
        </Copy>
      </Stack>
      <FormikComponent />
    </Stack>
  </Section>
);

interface ComplaintsFormValues {
  policyNumber: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  address: string;
  complaintDetails: string;
}

const getInitialValues = (): ComplaintsFormValues => ({
  policyNumber: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  address: '',
  complaintDetails: '',
});

interface BaseFormikProps extends FormikProps<ComplaintsFormValues> {}

const PersonalDetailsSection = (props: BaseFormikProps): JSX.Element => {
  return (
    <Stack space={4}>
      <Heading size={{ xs: 2, md: 3 }} component="h3">
        Personal details
      </Heading>
      <Columns space={{ xs: 2, xl: 7 }} collapseBelow="md">
        <Column width={{ md: '1/3', lg: '1/3' }}>
          <TextboxFormControl
            id="policy-number"
            value={props.values.policyNumber}
            isTouched={props.touched.policyNumber}
            fieldName="policyNumber"
            valid={!props.errors.policyNumber}
            autoComplete="off"
            label="Policy number (optional)"
            error={props.errors.policyNumber}
            isEmptyAndOptional={!props.values.policyNumber}
            {...props}
          />
        </Column>
        <Column width={{ md: '1/3', lg: '1/3' }}>
          <TextboxFormControl
            id="first-name"
            value={props.values.firstName}
            isTouched={props.touched.firstName}
            fieldName="firstName"
            valid={!props.errors.firstName}
            autoComplete="given-name"
            label="First name"
            error={props.errors.firstName}
            {...props}
          />
        </Column>
        <Column width={{ md: '1/3', lg: '1/3' }}>
          <TextboxFormControl
            id="last-name"
            value={props.values.lastName}
            isTouched={props.touched.lastName}
            fieldName="lastName"
            valid={!props.errors.lastName}
            autoComplete="family-name"
            label="Last name"
            error={props.errors.lastName}
            {...props}
          />
        </Column>
      </Columns>
      <Columns space={{ xs: 2, xl: 7 }} collapseBelow="md">
        <Column width="1/2">
          <TextboxFormControl
            id="phone-number"
            value={props.values.phoneNumber}
            isTouched={props.touched.phoneNumber}
            fieldName="phoneNumber"
            valid={!props.errors.phoneNumber}
            autoComplete="tel"
            label="Phone number (optional)"
            error={props.errors.phoneNumber}
            isEmptyAndOptional={!props.values.phoneNumber}
            {...props}
          />
        </Column>
        <Column width="1/2">
          <TextboxFormControl
            id="email"
            value={props.values.email}
            isTouched={props.touched.email}
            fieldName="email"
            valid={!props.errors.email}
            autoComplete="email"
            label="Email"
            error={props.errors.email}
            {...props}
          />
        </Column>
      </Columns>
      <Columns space={{ xs: 2, xl: 7 }} collapseBelow="md">
        <Column width="1">
          <TextboxFormControl
            id="address"
            value={props.values.address}
            isTouched={props.touched.address}
            fieldName="address"
            valid={!props.errors.address}
            autoComplete="street-address"
            label="Address (optional)"
            error={props.errors.address}
            isEmptyAndOptional={!props.values.address}
            {...props}
          />
        </Column>
      </Columns>
    </Stack>
  );
};

const ComplaintDetailsSection = (props: BaseFormikProps): JSX.Element => {
  const isSubmitSuccessfully: boolean = props.status && props.status.submitSucceeded;

  return (
    <Stack>
      <Heading size={{ xs: 2, md: 3 }} component="h3">
        Complaint details
      </Heading>
      <Box paddingBottom="3">
        <Columns space={{ xs: 2, xl: 7 }} collapseBelow="md">
          <Column width="1">
            <TextAreaFormControl
              id="complaintDetails"
              value={props.values.complaintDetails}
              isTouched={props.touched.complaintDetails}
              fieldName="complaintDetails"
              valid={!props.errors.complaintDetails}
              error={props.errors.complaintDetails}
              label=""
              {...props}
            />
          </Column>
        </Columns>
      </Box>
      <Box paddingBottom="3">
        {isSubmitSuccessfully && (
          <Alert
            type="success"
            title="Thank you. Your message has been received successfully."
            fullWidth={false}
          />
        )}
        {props.status && !props.status.submitSucceeded && props.status.errorMessage && (
          <Alert type="error" title="Oops, something went wrong" fullWidth={false}>
            <CommErrorMessage />
          </Alert>
        )}
      </Box>
      <PrimaryButton type="submit" disabled={props.isSubmitting || isSubmitSuccessfully}>
        Submit
      </PrimaryButton>
    </Stack>
  );
};

const InnerComplaintForm = (props: BaseFormikProps): JSX.Element => (
  <Form
    id="complaintForm"
    name="complaintForm"
    containerWidth={null}
    title="Submit a complaint to nib"
    onSubmit={props.handleSubmit}
  >
    <PersonalDetailsSection {...props} />
    <ComplaintDetailsSection {...props} />
  </Form>
);

const validate = (values: ComplaintsFormValues): any => {
  let errors: any = {};

  if (!isEmpty(values.policyNumber) && !values.policyNumber.match(/^[0][0]\d{6}$/)) {
    errors.policyNumber = 'Please provide a valid policy number';
  }

  validateNames(errors, values, 'firstName', 'first ');
  validateNames(errors, values, 'lastName', 'last ');
  validatePhoneNumbers(errors, values, 'phoneNumber');
  validateEmail(errors, values, 'email');

  if (isEmpty(values.complaintDetails)) {
    errors.complaintDetails = 'Please provide the complaint details';
  }

  if (
    !isEmpty(values.address) &&
    (values.address.match(/^[A-Za-z '-.]+$/) || values.address.match(/^[0-9'-.]+$/))
  ) {
    errors.address = 'Please provide a valid address';
  }

  return errors;
};

const FormikComponent = (): JSX.Element => (
  <Formik
    initialValues={getInitialValues()}
    validate={validate}
    onSubmit={(values, { setSubmitting, setStatus }) => {
      const complaintData: ComplaintModel = {
        policyId: values.policyNumber,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        emailAddress: values.email,
        address: values.address,
        complaintDetails: values.complaintDetails,
        complaintsReferenceId: '',
      };
      submitComplaint(complaintData)
        .then(() => {
          setStatus({
            submitSucceeded: true,
          });
          setSubmitting(false);
        })
        .catch((error) => {
          setStatus({
            submitSucceeded: false,
            errorMessage: error.message,
          });
          setSubmitting(false);
        });
    }}
    component={InnerComplaintForm}
  />
);

const ComplaintsPage = (): JSX.Element => (
  <Layout>
    <Helmet>
      <title>Complaints For Health Insurance | nib</title>
      <meta name="description" content={metaDescription} />
    </Helmet>
    <ComplaintSection />
  </Layout>
);

export default metrics({ pageName: 'complaints' })(ComplaintsPage);
